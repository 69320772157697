import * as React from 'react'
import { title, separator } from "./layout.module.scss";
import Hero from "./hero";
import Nav from "./nav";
import Footer from "./footer";
import SeparatorTilde from "./separatorTilde";

const Layout = ({ pageTitle, largeHero, children }) => {
    return (
        <div>
            <title>{pageTitle}</title>
            <main>
                {/* Hack to force fill height if content is < height, subtract footer height+padding. */}
                <div style={{ "min-height": "calc(100vh - 124px)" }}>
                    <Hero large={largeHero} />

                    <Nav />

                    <div className={separator}>
                        <SeparatorTilde />
                    </div>

                    {children}
                </div>

                <Footer />
            </main>
        </div>
    )
}

export default Layout