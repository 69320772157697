import * as React from 'react'

import { container, footer } from './footer.module.scss'

const Footer = () => {
    return (
        <div className={container}>
            <p className={footer}>© Sagotassens Kennel</p>
        </div>
    )
}

export default Footer