import * as React from 'react'

import { separatorTilde } from './separatorTilde.module.scss'

const SeparatorTilde = () => {
    return (
        <p className={separatorTilde}>~</p>
    )
}

export default SeparatorTilde