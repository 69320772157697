import * as React from 'react';

import { Link } from 'gatsby';
import { activeNavLink, navLinkItem, navLinks, navLinkText } from "./nav.module.scss";

const Nav = ({ pageTitle }) => {
    return (
        <nav>
            <ul className={navLinks}>
                <li className={navLinkItem}>
                    <Link to="/" className={navLinkText} activeClassName={activeNavLink}>Om oss</Link>
                </li>

                <li className={navLinkItem}>
                    <Link to="/hundarna" className={navLinkText} activeClassName={activeNavLink}>Hundarna</Link>
                </li>

                <li className={navLinkItem}>
                    <Link to="/valpar" className={navLinkText} activeClassName={activeNavLink}>Valpar</Link>
                </li>

                <li className={navLinkItem}>
                    <Link to="/till-minne" className={navLinkText} activeClassName={activeNavLink}>Till minne</Link>
                </li>

                <li className={navLinkItem}>
                    <Link to="/planer-och-kontakt" className={navLinkText} activeClassName={activeNavLink}>Kontakt</Link>
                </li>
            </ul>
        </nav>
    )
}

export default Nav