import * as React from 'react'

import ReactZoom from "react-medium-image-zoom";
import 'react-medium-image-zoom/dist/styles.css'

const Zoom = ({wrapperClass, children}) => {
    const zoom = (
        <ReactZoom overlayBgColorEnd={"rgba(0,0,0,0.5)"} zoomMargin={24}>
            {children}
        </ReactZoom>
    )

    return (
        wrapperClass ? <div className={wrapperClass}>{zoom}</div> : zoom
    )
}

export default Zoom